import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { format, subYears, differenceInCalendarDays } from "date-fns";
import {
  Dropdown,
  ExpandedStatList,
  Filter,
  HStack,
  Spacer,
  Text,
  VStack,
  EloTable,
} from "../components";
import { theme } from "../config";
import { useResource } from "../hooks";
import { navigate } from "@reach/router";

const OutterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (${theme.breakpoints.mediumAndUp}) {
    flex-direction: row;
    width: 70%;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  @media (${theme.breakpoints.mediumAndUp}) {
    flex-direction: row;
  }
`;
const TableContainer = styled.div`
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  width: 100%;
  flex-direction: column;
  border: 2px solid ${theme.colors.grey15};
`;

const ListItemContainer = styled.tr`
  box-sizing: border-box;
  width: 100%;
  padding: ${theme.spacing.x2} ${theme.spacing.x2};
  background: ${({ idx }) =>
    idx % 2 === 0 ? theme.colors.white100 : theme.colors.grey15};
  &:hover {
    background: ${({ isHeader }) =>
      isHeader ? theme.colors.grey15 : theme.colors.secondary20};
  }
`;

const Column = styled.td`
  display: ${({ hideOnMobile }) => (hideOnMobile ? "none" : "block")};
  width: ${({ width }) => width};
  padding: 0 ${theme.spacing.x1};
  @media (${theme.breakpoints.mediumAndUp}) {
    display: block;
  }
`;
const Header = styled.th`
  display: ${({ hideOnMobile }) => (hideOnMobile ? "none" : "block")};
  width: ${({ width }) => width};
  padding: 0 ${theme.spacing.x1};
  font-size: ${theme.spacing.x4};
  color: ${theme.colors.secondary100};
  text-align: ${({ align }) => (align ? align : "left")};
  font-weight: ${theme.fontWeight.bold};
  @media (${theme.breakpoints.mediumAndUp}) {
    font-size: ${theme.spacing.x5};
    display: block;
  }
`;
const Container = styled.table`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  margin-top: ${theme.spacing.x4};
  flex-direction: column;
  @media (${theme.breakpoints.mediumAndUp}) {
    margin-top: 0;
  }
`;

const Image = styled.img`
  height: 50px;
  @media (${theme.breakpoints.mediumAndUp}) {
    height: 70px;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: flex-start;
  @media (${theme.breakpoints.mediumAndUp}) {
    align-items: center;
  }
`;

const AdvertContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (${theme.breakpoints.mediumAndUp}) {
    width: 29%;
  }
`;

const Advert = styled.img`
  display: flex;
  background: ${theme.colors.secondary50};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  border: 2px solid ${theme.colors.grey15};
  align-items: center;
  justify-content: center;
  max-width: 100%;
`;

const ListItem = ({ item, idx }) => {
  return (
    <ListItemContainer idx={idx}>
      <Spacer height="x2" />
      <HStack align="center" justify="space-between">
        <Column width={"5%"}>
          <Text
            color="grey100"
            size="x3"
            weight="bold"
          >{`${item.attributes.powerAverageRank}.`}</Text>
        </Column>
        <Column width={"15%"}>
          <img
            alt="Flag"
            src={`https://data.fei.org/Content/Images/Flags/${item.attributes.nf}.png`}
          />
        </Column>
        <Column width={"40%"}>
          <Text color="grey100" size="x4" mobileSize="x2" weight="semibold">
            {item.attributes.name}
          </Text>
          <Text color="grey80" size="x3" mobileSize="x1" weight="semibold">
            {item.attributes.results[0].with}
          </Text>
        </Column>
        <Column width={"10%"} hideOnMobile>
          <Text size="x4" weight="normal" align="right" color="grey100">
            {item.attributes.results[0].score}
          </Text>
        </Column>
        <Column width={"10%"} hideOnMobile>
          <Text size="x4" weight="normal" align="right" color="grey100">
            {item.attributes.results[1].score}
          </Text>
        </Column>
        <Column width={"10%"} hideOnMobile>
          <Text size="x4" weight="normal" align="right" color="grey100">
            {item.attributes.results[2].score}
          </Text>
        </Column>
        <Column width={"10%"}>
          <Text size="x4" weight="bold" align="right" color="grey100">
            {item.attributes.powerAverage}
          </Text>
        </Column>
      </HStack>
      <Spacer height="x2" />
    </ListItemContainer>
  );
};

const BannerImage = styled.img`
  display: none;
  @media (${theme.breakpoints.mediumAndUp}) {
    width: 100%;
    display: flex;
    margin-bottom: ${theme.spacing.x6};
    background: ${theme.colors.secondary50};
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    border: 2px solid ${theme.colors.grey15};
    align-items: center;
    justify-content: center;
  }
`;

const PowerAverageLeague = ({ partner }, ...props) => {
  const [paIndex, setPaIndex] = useState(10);
  const { getAll: getAllPowerAverages, all: powerAverages } = useResource(
    `power_average?group_by=horse&return_top=100&date_from=2022-01-01&date_to=9999-12-12&`
  );

  useEffect(() => {
    getAllPowerAverages();
  }, []);

  return powerAverages ? (
    <>
      <HStack align="center" justify="space-between">
        <Text size="x6" weight="semibold">
          Equilume Power Average League
        </Text>
        <ImageContainer>
          <Image alt="EquiLume Logo" src={require("../assets/equilume.png")} />
        </ImageContainer>
      </HStack>
      <Spacer height="x6" />
      <Text size="x5" weight="normal">
        <b>Power Average</b> – Shining a light on repeated excellence
      </Text>
      <Spacer height="x2" />
      <Text size="x5" weight="normal">
        <b>Prize</b>: Prize money of €1,000 will be awarded to the rider who
        finishes the season with the best Equilume Power Average.
      </Text>
      <Spacer height="x2" />
      <Text size="x5" weight="normal">
        The Equilume Power Average is the average of a horse’s three best
        finishing scores from all Eventing Ireland events in the 2022 season.
        Horses must have three or more completions to be eligible to win this
        league. All levels of competition are treated equally, allowing horses
        of all ages and experience to compete for the 2022 Equilume league
        title.
      </Text>
      <Spacer height="x6" />
      <BannerImage src={require("../assets/1400x100.png")} width="100%" />
      <Wrapper>
        <OutterWrapper>
          <Container>
            <TableContainer>
              <ListItemContainer isHeader={true}>
                <Spacer height="x2" />
                <HStack align="center" justify="space-between">
                  <Header width="5%"> </Header>
                  <Header width="15%">Nation</Header>
                  <Header width="40%">Horse</Header>
                  <Header width="10%" align="right" hideOnMobile>
                    First Result
                  </Header>
                  <Header width="10%" align="right" hideOnMobile>
                    Second Result
                  </Header>
                  <Header width="10%" align="right" hideOnMobile>
                    Third Result
                  </Header>
                  <Header width="10%" align="right">
                    Power Avg
                  </Header>
                </HStack>
                <Spacer height="x2" />
              </ListItemContainer>
              {powerAverages.data.slice(0, paIndex).map((item, idx) => {
                return <ListItem key={idx} item={item} idx={idx} />;
              })}
              {paIndex < powerAverages.data.length && (
                <div
                  style={{
                    padding: theme.spacing.x2,
                    background: theme.colors.grey15,
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => setPaIndex(powerAverages.data.length)}
                >
                  <Text
                    align="right"
                    size="x5"
                    weight="medium"
                    color="secondary100"
                  >
                    See More
                  </Text>
                </div>
              )}
            </TableContainer>
          </Container>
        </OutterWrapper>
        <Spacer height="x4" />
        <AdvertContainer>
          <Advert alt="Advert" src={require("../assets/equilume.gif")} />
          <Spacer height="x4" />
          <Advert alt="Advert" src={require("../assets/600x600.jpg")} />
        </AdvertContainer>
      </Wrapper>
    </>
  ) : null;
};

export default PowerAverageLeague;
