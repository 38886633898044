import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Dropdown,
  ExpandedStatList,
  Filter,
  HStack,
  Spacer,
  Text,
  VStack,
} from "../components";
import { theme } from "../config";
import { useResource } from "../hooks";

const Banner = styled.div`
  width: 100%;
  height: 20%;
  position: fixed;
  background-image: url(https://images.squarespace-cdn.com/content/v1/58810210197aea38928d567d/1488393188386-4FK72GNYDQP0ZLUAR941/ke17ZwdGBToddI8pDm48kBY-qbJWPsJyl0YWCpcFDbUUqsxRUqqbr1mOJYKfIPR7LoDQ9mXPOjoJoqy81S2I8N_N4V1vUb5AoIIIbLZhVYy7Mythp_T-mtop-vrsUOmeInPi9iDjx9w8K4ZfjXt2dtpiMAtR4kZNhqC87Kai_7eqPC5xDA4RuAfsTyVeslMUZDqXZYzu2fuaodM4POSZ4w/Discover+Eventing+Banner.jpg?format=2500w);
  background-color: ${theme.colors.grey100};
  opacity: 0.8;
`;

const BannerOverlay = styled.div`
  width: 100%;
  height: 20%;
  position: fixed;
  background-color: ${theme.colors.grey100};
  opacity: 0.4;
`;

const Divider = styled.div`
  height: 2px;
  width: 100%;
  background: ${theme.colors.grey40};
`;

const ListsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: wrap;
  @media (${theme.breakpoints.mediumAndUp}) {
    flex-direction: row;
  }
`;

const TitleContainer = styled.div`
  flex-wrap: wrap;
  display: flex;
  @media (${theme.breakpoints.mediumAndUp}) {
    flex-wrap: no-wrap;
  }
`;

const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  @media (${theme.breakpoints.mediumAndUp}) {
    flex-direction: row;
    width: 70%;
    align-items: top;
  }
`;

const OutterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: top;
  @media (${theme.breakpoints.mediumAndUp}) {
    flex-direction: row;
  }
`;

const FilterContainer = styled.div`
  display: flex;
  margin-top: ${theme.spacing.x4};
  @media (${theme.breakpoints.mediumAndUp}) {
    margin-top: 0;
  }
`;

const SingleMetric = (props) => {
  const [activeFilter, setActiveFilter] = useState(0);
  const [groupBy, setGroupBy] = useState("athlete");
  const [nation, setSelectedNation] = useState("All Nations");
  const [selectedLevels, setSelectedLevel] = useState(
    props.location.state.selectedLevels || [
      { title: "CCI5*-L", level: 13 },
      { title: "CCI4*-L", level: 12 },
      { title: "CCI4*-S", level: 11 },
    ]
  );
  const [selectedYears, setSelectedYear] = useState(
    props.location.state.selectedYears || [2020]
  );
  let levelParams = "";
  if (selectedLevels.length == 13) {
    selectedLevels.forEach((level) => {
      levelParams = levelParams + `&er_levels[]=${level}`;
    });
  } else {
    selectedLevels.forEach((level) => {
      levelParams = levelParams + `&er_levels[]=${level.level}`;
    });
  }
  let yearParams = "";
  selectedYears.forEach((year) => {
    yearParams = yearParams + `&years[]=${year}`;
  });

  const { getAll: getAllRecords, all: records } = useResource(
    `records?metric_type=${props.location.state.metric.metric.toLowerCase()}&group_by=${groupBy}${
      nation === "All Nations" ? "" : `&nf=${nation}`
    }&return_top=100${levelParams}${yearParams}`
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    getAllRecords();
  }, [selectedLevels, selectedYears, groupBy, nation]);

  const removeClass = (index) => {
    const newArr = [...selectedLevels];
    newArr.splice(index, 1);
    setSelectedLevel(newArr);
  };

  const camelize = (s) => {
    return s.replace(/([-_][a-z])/gi, ($1) => {
      return $1.toUpperCase().replace("-", "").replace("_", "");
    });
  };

  return (
    <>
      <Text size="x6" weight="semibold">
        {props.location.state.metric.title}
      </Text>
      <Spacer height="x4" />
      <OutterWrapper>
        <DropdownContainer>
          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            <Dropdown
              onChange={(item) => {
                if (item.title == "All Levels") {
                  setSelectedLevel(item.level);
                } else {
                  if (selectedLevels.length == 13) {
                    setSelectedLevel([item]);
                  } else {
                    setSelectedLevel([...selectedLevels, item]);
                  }
                }
              }}
              readOnly={true}
              items={[
                {
                  title: "All Levels",
                  level: [13, 12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1],
                },
                { title: "CCI5*-L", level: 13 },
                { title: "CCI4*-L", level: 12 },
                { title: "CCI4*-S", level: 11 },
                { title: "Advanced", level: 10 },
                { title: "CCI3*-L", level: 9 },
                { title: "CCI3*-S", level: 8 },
                { title: "Intermediate", level: 7 },
                { title: "CCI2*-L", level: 6 },
                { title: "CCI2*-S", level: 5 },
                { title: "Novice", level: 4 },
                { title: "CCI1*-U & BE105", level: 3 },
                { title: "BE100", level: 2 },
                { title: "BE80(T) and BE90", level: 1 },
              ]}
              itemToString={(item) => "Select a Level"}
              initialItem={selectedLevels[0]}
              handleFilter={() => () => true}
              displaySuggestion={(item) => (
                <HStack align="center">
                  <input
                    type="checkbox"
                    style={{ backgroundColor: theme.colors.primary100 }}
                    checked={selectedLevels
                      .map((lvl) => lvl.level)
                      .includes(item.level)}
                    onChange={() => null}
                  />
                  <Text size="x3">{item.title}</Text>
                </HStack>
              )}
            />
            <div style={{ display: "flex", width: "100%", flexWrap: "wrap" }}>
              {selectedLevels.length == 13 ? (
                <div
                  style={{
                    marginTop: theme.spacing.x1,
                    marginLeft: theme.spacing.x1,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Text size="x4">All Levels</Text>
                  <Spacer width="x2" />
                  <svg
                    onClick={() =>
                      setSelectedLevel([
                        { title: "CCI4*-L", level: 12 },
                        { title: "CCI4*-S", level: 11 },
                        { title: "EI120", level: 10 },
                      ])
                    }
                    style={{ cursor: "pointer" }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="10"
                    viewBox="0 0 24 24"
                  >
                    <path d="M23.954 21.03l-9.184-9.095 9.092-9.174L21.03-.046l-9.09 9.179L2.764.045l-2.81 2.81L9.14 11.96.045 21.144l2.81 2.81 9.112-9.192 9.18 9.1z"></path>
                  </svg>
                  <Spacer width="x4" />
                </div>
              ) : (
                selectedLevels.map((level, idx) => {
                  if (selectedLevels.length > 1) {
                    return (
                      <div
                        style={{
                          marginTop: theme.spacing.x1,
                          marginLeft: theme.spacing.x1,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Text size="x4">{level.title}</Text>
                        <Spacer width="x2" />
                        <svg
                          onClick={() => removeClass(idx)}
                          style={{ cursor: "pointer" }}
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          height="10"
                          viewBox="0 0 24 24"
                        >
                          <path d="M23.954 21.03l-9.184-9.095 9.092-9.174L21.03-.046l-9.09 9.179L2.764.045l-2.81 2.81L9.14 11.96.045 21.144l2.81 2.81 9.112-9.192 9.18 9.1z"></path>
                        </svg>
                        <Spacer width="x4" />
                      </div>
                    );
                  } else {
                    return (
                      <div
                        style={{
                          marginLeft: theme.spacing.x1,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Text size="x4">Selected levels:</Text>
                        <Spacer width="x3" />
                        <Text size="x4">{level.title}</Text>
                      </div>
                    );
                  }
                })
              )}
            </div>
          </div>
          <Dropdown
            onChange={(item) => setSelectedYear([item])}
            readOnly={true}
            items={[2021, 2020, 2019, 2018, 2017]}
            itemToString={(item) => item}
            initialItem={selectedYears[0]}
            handleFilter={() => () => true}
            displaySuggestion={(item) => <Text size="x3">{item}</Text>}
          />
        </DropdownContainer>
        <FilterContainer>
          <div
            onClick={() => {
              setActiveFilter(0);
              setGroupBy("athlete");
            }}
          >
            <Filter active={activeFilter === 0} text="Rider" />
          </div>
          <div
            onClick={() => {
              setActiveFilter(1);
              setGroupBy("horse");
            }}
          >
            <Filter active={activeFilter === 1} text="Horse" />
          </div>
        </FilterContainer>
      </OutterWrapper>
      <Spacer height="x4" />
      <Spacer height="x8" />
      {records &&
      records.data.attributes[camelize(props.location.state.metric.metric)]
        .length > 0 ? (
        <ExpandedStatList
          title={props.location.state.metric.title}
          list={
            records.data.attributes[
              camelize(props.location.state.metric.metric)
            ]
          }
        />
      ) : (
        <Text size="x5" color="grey30" weight="semibold" align="center">
          There are no records matching your search.
        </Text>
      )}
    </>
  );
};

export default SingleMetric;
