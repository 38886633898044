import React from "react";
import styled from "styled-components";
import Text from "./Text";
import HStack from "./HStack";
import Spacer from "./Spacer";
import { navigate } from "@reach/router";
import { theme } from "../config";

const HeaderContainer = styled.header`
  background: ${theme.colors.white100};
  border-bottom: 0.5px solid ${theme.colors.grey30};
  padding: ${theme.spacing.x6} ${theme.spacing.x4};
`;

const RightTextContainer = styled.div`
  display: none;
  @media (${theme.breakpoints.mediumAndUp}) {
    display: inline-block;
  }
`;

const LeftText = styled(Text)`
  font-size: ${theme.fontScale.x4};
  @media (${theme.breakpoints.mediumAndUp}) {
    font-size: ${theme.fontScale.x6};
  }
`;

const HamburgerContainer = styled.div`
  display: flex;
  cursor: pointer;
  margin-right: 10%;
  @media (${theme.breakpoints.mediumAndUp}) {
    display: none;
  }
`;

const MainContentContainer = styled.div`
  display: flex;
  align-content: center;
  align-self: flex-end;
  @media (${theme.breakpoints.mediumAndUp}) {
    align-self: flex-start;
  }
`;

const Image = styled.img`
  height: 40px;
  @media (${theme.breakpoints.mediumAndUp}) {
    height: 50px;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: flex-start;
  @media (${theme.breakpoints.mediumAndUp}) {
    align-items: center;
  }
`;

const Header = ({ setShowSidebar, showSidebar }) => {
  return (
    <HeaderContainer>
      <HStack align="center" justify="space-between">
        <HStack align="center">
          <HamburgerContainer onClick={() => setShowSidebar(!showSidebar)}>
            <svg
              fill={theme.colors.primary100}
              width="30"
              height="30"
              viewBox="0 0 100 70"
            >
              <path d="M0 0h100v10H0zM0 30h100v10H0zM0 60h100v10H0z" />
            </svg>
          </HamburgerContainer>
          <ImageContainer>
            <Image src={require("../assets/headerLogo.png")} />
          </ImageContainer>
        </HStack>
      </HStack>
    </HeaderContainer>
  );
};

export default Header;
