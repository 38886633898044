import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { format, subYears, differenceInCalendarDays } from "date-fns";
import {
  Dropdown,
  ExpandedStatList,
  Filter,
  HStack,
  Spacer,
  Text,
  VStack,
  EloTable,
} from "../components";
import { theme } from "../config";
import { useResource } from "../hooks";
import { navigate } from "@reach/router";

const OutterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (${theme.breakpoints.mediumAndUp}) {
    flex-direction: row;
    width: 70%;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  @media (${theme.breakpoints.mediumAndUp}) {
    flex-direction: row;
  }
`;
const TableContainer = styled.div`
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  width: 100%;
  flex-direction: column;
  border: 2px solid ${theme.colors.grey15};
`;

const ListItemContainer = styled.tr`
  box-sizing: border-box;
  width: 100%;
  padding: ${theme.spacing.x2} ${theme.spacing.x2};
  background: ${({ idx }) =>
    idx % 2 === 0 ? theme.colors.white100 : theme.colors.grey15};
  &:hover {
    background: ${({ isHeader }) =>
      isHeader ? theme.colors.grey15 : theme.colors.secondary20};
  }
`;

const Column = styled.td`
  display: ${({ hideOnMobile }) => (hideOnMobile ? "none" : "block")};
  width: ${({ width }) => width};
  padding: 0 ${theme.spacing.x1};
  @media (${theme.breakpoints.mediumAndUp}) {
    display: block;
  }
`;
const Header = styled.th`
  display: ${({ hideOnMobile }) => (hideOnMobile ? "none" : "block")};
  width: ${({ width }) => width};
  padding: 0 ${theme.spacing.x1};
  font-size: ${theme.spacing.x4};
  color: ${theme.colors.secondary100};
  text-align: ${({ align }) => (align ? align : "left")};
  font-weight: ${theme.fontWeight.bold};
  @media (${theme.breakpoints.mediumAndUp}) {
    font-size: ${theme.spacing.x5};
    display: block;
  }
`;
const Container = styled.table`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  margin-top: ${theme.spacing.x4};
  flex-direction: column;
  @media (${theme.breakpoints.mediumAndUp}) {
    margin-top: 0;
  }
`;

const Image = styled.img`
  height: 50px;
  @media (${theme.breakpoints.mediumAndUp}) {
    height: 70px;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: flex-start;
  @media (${theme.breakpoints.mediumAndUp}) {
    align-items: center;
  }
`;

const AdvertContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (${theme.breakpoints.mediumAndUp}) {
    width: 29%;
  }
`;

const Advert = styled.img`
  display: flex;
  background: ${theme.colors.secondary50};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  border: 2px solid ${theme.colors.grey15};
  align-items: center;
  justify-content: center;
  cursor: pointer;
  max-width: 100%;
`;

const ListItem = ({ item, idx }) => {
  return (
    <ListItemContainer idx={idx}>
      <Spacer height="x2" />
      <HStack align="center" justify="space-between">
        <Column width={"5%"}>
          <Text
            color="grey100"
            size="x3"
            weight="bold"
          >{`${item.attributes.rank}.`}</Text>
        </Column>
        <Column width={"15%"}>
          <img
            src={`https://data.fei.org/Content/Images/Flags/${item.attributes.nf}.png`}
          />
        </Column>
        <Column width={"35%"}>
          <Text color="grey100" size="x4" mobileSize="x2" weight="semibold">
            {item.attributes.name}
          </Text>
          <Text color="grey80" size="x3" mobileSize="x1" weight="semibold">
            {item.attributes.with}
          </Text>
        </Column>
        <Column width={"10%"}>
          <Text size="x4" weight="normal" align="right" color="grey100">
            {item.attributes.obpCount}
          </Text>
        </Column>
        <Column width={"15%"} hideOnMobile>
          <Text size="x4" weight="normal" align="right" color="grey100">
            {item.attributes.obpOcSum}
          </Text>
        </Column>
        <Column width={"10%"} hideOnMobile>
          <Text size="x4" weight="normal" align="right" color="grey100">
            {item.attributes.obpObSum}
          </Text>
        </Column>
        <Column width={"10%"}>
          <Text size="x4" weight="bold" align="right" color="grey100">
            {`${(parseFloat(item.attributes.obp) * 100).toFixed(1)}%`}
          </Text>
        </Column>
      </HStack>
      <Spacer height="x2" />
    </ListItemContainer>
  );
};

const BannerImage = styled.img`
  display: none;
  @media (${theme.breakpoints.mediumAndUp}) {
    width: 100%;
    display: flex;
    margin-bottom: ${theme.spacing.x6};
    background: ${theme.colors.secondary50};
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    border: 2px solid ${theme.colors.grey15};
    align-items: center;
    justify-content: center;
  }
`;

const OBPLeagues = ({ partner }, ...props) => {
  const [obpIndex, setObpIndex] = useState(10);
  const { getAll: getAllObps, all: obps } = useResource(
    `obp?group_by=horse&return_top=100&date_from=2022-01-01&date_to=9999-12-12&min_counting_runs=3&`
  );

  useEffect(() => {
    getAllObps();
  }, []);

  return obps
    ? console.log("HERE YA GO", obps) || (
        <>
          <HStack align="center" justify="space-between">
            <Text size="x6" weight="semibold">
              {partner == "Equilume"
                ? "Equilume OBP League"
                : "Red Mills OBP League"}
            </Text>
            <ImageContainer>
              {partner == "Equilume" ? (
                <Image src={require("../assets/equilume.png")} />
              ) : (
                <Image src={require("../assets/redmills.png")} />
              )}
            </ImageContainer>
          </HStack>
          <Spacer height="x6" />
          <Text size="x5" weight="normal">
            <b>Opposition Beaten Percentage (OBP)</b> – Highlighting the
            consistently competitive horse
          </Text>
          <Spacer height="x2" />
          <Text size="x5" weight="normal">
            <b>Prize</b>: Prize money of €1,000 will be awarded to the rider
            whose horse finishes the season with the best OBP. Plus, prizes from
            the RED MILLS Equine Hub, including products from the RED MILLS,
            Foran Equine and Carr & Day & Martin, will be awarded for
            achievements throughout the season.
          </Text>
          <Spacer height="x2" />
          <Text size="x5" weight="normal">
            The Opposition Beaten Percentage (OBP) is the percentage of
            opponents a horse has beaten. OBP only considers competitions where
            a horse starts the cross-country phase. Each horse with three or
            more cross-country starts throughout the 2022 Eventing Ireland
            season will be in the running for the prize. See the leaderboard
            below for the race to claim the title of Eventing Ireland’s most
            consistently competitive horse.
          </Text>
          <Spacer height="x6" />
          <BannerImage
            src={require("../assets/RedMillsBanner.png")}
            width="100%"
          />
          <Wrapper>
            <OutterWrapper>
              <Container>
                <TableContainer>
                  <ListItemContainer isHeader={true}>
                    <Spacer height="x2" />
                    <HStack align="center" justify="space-between">
                      <Header width="5%"> </Header>
                      <Header width="15%">Nation</Header>
                      <Header width="35%">
                        {partner == "Equilume" ? "Horse" : "Athlete"}
                      </Header>
                      <Header width="10%" align="right">
                        Runs
                      </Header>
                      <Header width="15%" align="right" hideOnMobile>
                        Contested
                      </Header>
                      <Header width="10%" align="right" hideOnMobile>
                        Beaten
                      </Header>
                      <Header width="10%" align="right">
                        OBP
                      </Header>
                    </HStack>
                    <Spacer height="x2" />
                  </ListItemContainer>
                  {obps.data.slice(0, obpIndex).map((item, idx) => {
                    return <ListItem key={idx} item={item} idx={idx} />;
                  })}
                  {obpIndex < obps.data.length && (
                    <div
                      style={{
                        padding: theme.spacing.x2,
                        background: theme.colors.grey15,
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      onClick={() => setObpIndex(obps.data.length)}
                    >
                      <Text
                        align="right"
                        size="x5"
                        weight="medium"
                        color="secondary100"
                      >
                        See More
                      </Text>
                    </div>
                  )}
                </TableContainer>
              </Container>
            </OutterWrapper>
            <Spacer height="x4" />
            <AdvertContainer>
              <Advert
                src={require("../assets/RedMills1.png")}
                onClick={() => window.open("https://www.redmills.ie/")}
              />
              <Spacer height="x4" />
              <Advert
                src={require("../assets/RedMills2.png")}
                onClick={() => window.open("https://www.carrdaymartin.com/en/")}
              />
              <Spacer height="x4" />
              <Advert
                src={require("../assets/RedMills3.png")}
                onClick={() => window.open("https://www.foranequine.ie/")}
              />
            </AdvertContainer>
          </Wrapper>
        </>
      )
    : null;
};

export default OBPLeagues;
