import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {format, subYears, differenceInCalendarDays} from 'date-fns';
import {
  Dropdown,
  ExpandedStatList,
  Filter,
  HStack,
  Spacer,
  Text,
  VStack,
  EloTable,
} from '../components';
import {theme} from '../config';
import {useResource} from '../hooks';
import {navigate} from '@reach/router';

const Banner = styled.div`
  width: 100%;
  height: 20%;
  position: fixed;
  background-image: url(https://images.squarespace-cdn.com/content/v1/58810210197aea38928d567d/1488393188386-4FK72GNYDQP0ZLUAR941/ke17ZwdGBToddI8pDm48kBY-qbJWPsJyl0YWCpcFDbUUqsxRUqqbr1mOJYKfIPR7LoDQ9mXPOjoJoqy81S2I8N_N4V1vUb5AoIIIbLZhVYy7Mythp_T-mtop-vrsUOmeInPi9iDjx9w8K4ZfjXt2dtpiMAtR4kZNhqC87Kai_7eqPC5xDA4RuAfsTyVeslMUZDqXZYzu2fuaodM4POSZ4w/Discover+Eventing+Banner.jpg?format=2500w);
  background-color: ${theme.colors.grey100};
  opacity: 0.8;
`;

const BannerOverlay = styled.div`
  width: 100%;
  height: 20%;
  position: fixed;
  background-color: ${theme.colors.grey100};
  opacity: 0.4;
`;

const Divider = styled.div`
  height: 2px;
  width: 100%;
  background: ${theme.colors.grey40};
`;

const ListsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: wrap;
  @media (${theme.breakpoints.mediumAndUp}) {
    flex-direction: row;
  }
`;

const TitleContainer = styled.div`
  flex-wrap: wrap;
  display: flex;
  @media (${theme.breakpoints.mediumAndUp}) {
    flex-wrap: no-wrap;
  }
`;

const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  @media (${theme.breakpoints.mediumAndUp}) {
    flex-direction: row;
    width: 70%;
    align-items: top;
  }
`;

const OutterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  @media (${theme.breakpoints.mediumAndUp}) {
    flex-direction: row;
    width: 50%;
  }
`;

const FilterContainer = styled.div`
  display: flex;
  margin-top: ${theme.spacing.x4};
  @media (${theme.breakpoints.mediumAndUp}) {
    margin-top: 0;
  }
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 30px;
`;

const Input = styled.input`
  box-sizing: border-box;
  border: 2px solid ${theme.colors.grey20};
  font-size: ${({fontSize}) => fontSize || theme.fontScale.x4};
  font-weight: ${theme.fontWeight.semibold};
  color: ${theme.colors.primary100};
  padding: ${({padding}) => (padding ? padding : theme.spacing.x2)};
  outline: none;
  width: 100%;
`;

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const EloSearch = props => {
  const [horseName, setHorseName] = useState('');
  const [riderName, setRiderName] = useState('');

  const todaysDate = format(Date.now(), 'yyyy-MM-dd');
  const twoYearsAgo = subYears(Date.now(), 2);

  let horseFilter = '';
  let athleteFilter = '';
  if (horseName) {
    horseFilter = `&horses_filter[name_contains]=${horseName}`;
  }
  if (riderName) {
    athleteFilter = `&athletes_filter[display_name_contains]=${riderName}`;
  }

  const {getAll: getAllElos, all: elos} = useResource(
    `elo?return_top=25&date_from=${format(
      twoYearsAgo,
      'yyyy-MM-dd',
    )}${horseFilter}${athleteFilter}&`,
  );
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getAllElos();
  }, [getAllElos, horseName, riderName]);

  return (
    <>
      <HeaderContainer>
        <Text size="x6" weight="semibold">
          Search for a specific Rider/Horse
        </Text>
      </HeaderContainer>
      <Spacer height="x4" />
      <OutterWrapper>
        <div style={{width: '98%'}}>
          <Text size="x4" weight="semibold">
            Search for a Rider
          </Text>
          <Input
            placeholder="Enter name"
            onChange={e => setRiderName(e.target.value)}
          />
        </div>
        <Spacer width="x2" />
        <div style={{width: '98%'}}>
          <Text size="x4" weight="semibold">
            Search for a Horse
          </Text>
          <Input
            placeholder="Enter name"
            onChange={e => setHorseName(e.target.value)}
          />
        </div>
      </OutterWrapper>
      <Spacer height="x4" />
      {elos ? (
        <EloTable title={'ELO Rankings'} list={elos.data} />
      ) : (
        <Text size="x5" color="grey30" weight="semibold" align="center">
          Please enter a name for a Rider/Horse
        </Text>
      )}
      <Spacer height="x8" />
    </>
  );
};

export default EloSearch;
