import React from "react";
import { theme } from "../config";

const Chevron = () => (
  <svg
    width="20"
    height="13"
    cursor="pointer"
    viewBox="0 0 39 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.96875 2.16669L19.4688 19.6667L36.9688 2.16669"
      stroke={theme.colors.primary100}
      strokeWidth="2"
      strokeLinecap="square"
    />
  </svg>
);

export default Chevron;
