import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {StatList, Filter, HStack, Spacer, Text} from '../components';
import {theme} from '../config';
import {useResource} from '../hooks';

const Banner = styled.div`
  width: 100%;
  height: 20%;
  position: fixed;
  background-image: url(https://images.squarespace-cdn.com/content/v1/58810210197aea38928d567d/1488393188386-4FK72GNYDQP0ZLUAR941/ke17ZwdGBToddI8pDm48kBY-qbJWPsJyl0YWCpcFDbUUqsxRUqqbr1mOJYKfIPR7LoDQ9mXPOjoJoqy81S2I8N_N4V1vUb5AoIIIbLZhVYy7Mythp_T-mtop-vrsUOmeInPi9iDjx9w8K4ZfjXt2dtpiMAtR4kZNhqC87Kai_7eqPC5xDA4RuAfsTyVeslMUZDqXZYzu2fuaodM4POSZ4w/Discover+Eventing+Banner.jpg?format=2500w);
  background-color: ${theme.colors.grey100};
  opacity: 0.8;
`;

const BannerOverlay = styled.div`
  width: 100%;
  height: 20%;
  position: fixed;
  background-color: ${theme.colors.grey100};
  opacity: 0.4;
`;

const Divider = styled.div`
  height: 2px;
  width: 100%;
  background: ${theme.colors.grey40};
`;

const RecordsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (${theme.breakpoints.mediumAndUp}) {
    flex-direction: row;
  }
`;

const TitleContainer = styled.div`
  flex-wrap: wrap;
  display: flex;
  @media (${theme.breakpoints.mediumAndUp}) {
    flex-wrap: no-wrap;
  }
`;

const RecordBoxContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  border: 2px solid ${theme.colors.grey40};
  margin-bottom: ${theme.spacing.x4};
  @media (${theme.breakpoints.mediumAndUp}) {
    width: 50%;
  }
`;

const RecordTitleContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: ${theme.spacing.x2} ${theme.spacing.x4};
  background: ${theme.colors.primary100};
`;

const RecordItemContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  width: 100%;
  border-bottom: ${({showBorder}) =>
    showBorder ? `2px solid ${theme.colors.grey40}` : 'none'};
`;

const RecordName = styled.div`
  padding: ${theme.spacing.x3} ${theme.spacing.x4};
  width: 50%;
  border-right: ${({showBorder}) =>
    showBorder ? ` 2px solid ${theme.colors.grey40}` : 'none'};
`;

const RecordItem = ({name, record, showBorder}) => {
  return (
    <RecordItemContainer showBorder={showBorder}>
      <RecordName showBorder>
        <Text size="x3" weight="semibold">
          {name}
        </Text>
      </RecordName>
      <RecordName>
        <Text size="x3">{record}</Text>
      </RecordName>
    </RecordItemContainer>
  );
};

const RecordBox = ({title, items}) => {
  return (
    <RecordBoxContainer>
      <RecordTitleContainer>
        <Text color="white100" weight="semibold" size="x4">
          {title}
        </Text>
      </RecordTitleContainer>
      {items.map((item, idx) => {
        return (
          <RecordItem
            name={item.name}
            record={item.record}
            showBorder={idx !== items.length - 1}
          />
        );
      })}
    </RecordBoxContainer>
  );
};

const Records = () => {
  const [activeFilter, setActiveFilter] = useState(0);

  const records = [
    {
      title: 'Dressage Records',
      items: [
        {
          name:
            'Lowest Average DR Score in a season (Riders is 5 or more runs)',
          record: 'Hannah Reeser - 2019 - 23.2',
        },
        {
          name: 'Most sub-28 DR Scores in a season',
          record: 'Bruce Davidson - 2015 - 57',
        },
        {
          name: '-',
          record: '-',
        },
      ],
    },
    {
      title: 'Cross Country Records',
      items: [
        {
          name: 'Most XCJ Clear Rounds in a season',
          record: 'Boyd Martin - 2015 - 166',
        },
        {
          name: 'Most XCT Clears in a season',
          record: 'Jennifer Brannigan - 2014 - 102',
        },
        {
          name: 'Most XCJ Clear Rounds in a season (Advanced and above)',
          record: 'Bruce Davidson - 2015 - 34',
        },
        {
          name: 'Most XCT Clears in a season (Advanced and above)',
          record: 'Phillip Dutton - 2015 - 8',
        },
        {
          name:
            'Highest number of XC runs in a season and still 100% clear (All Levels)',
          record: 'Doug Payne - 2017 - 61',
        },
        {
          name:
            'Highest number of XC runs in a season and still 100% clear (Advanced and above)',
          record: 'Ryan Wood - 2015 - 30',
        },
        {
          name: 'Longest XCJ Clear Streak Rounds in a season',
          record: 'Ryan Wood - 2017-2018 - 162',
        },
      ],
    },
    {
      title: 'Showjumping Records',
      items: [
        {
          name: 'Most SJ Clear Rounds in a season',
          record: 'Ryan Wood - 2015 - 105',
        },
        {
          name: 'Most SJ Clear Rounds in a season (Advanced and above)',
          record: 'Bruce Davidson - 2015 - 22',
        },
        // {
        //   name: 'Highest number of SJ runs in a season and still 100% clear',
        //   record: '',
        // },
        {
          name: 'Highest number of SJ runs in a season and still 100% clear',
          record: 'Alexa Gartenberg - 2014 - 20',
        },
        {
          name:
            'Highest number of SJ runs in a season and still 100% clear (Advanced and above)',
          record: 'Heather Morris - 2016 - 4',
        },
        {
          name: 'Longest SJ Clear Streak',
          record: 'Caroline Martin - 2020 - 42',
        },
      ],
    },
    {
      title: 'Overall Records',
      items: [
        {
          name: 'Most Wins in a Season',
          record: 'Jennifer Brannigan - 2014 - 45',
        },
        {
          name: 'Most Wins in a Season (Advanced and above)',
          record: 'Tamira Smith - 2018 - 6/Lauren Nicholson - 2015 - 6',
        },
        {
          name: 'Most podiums in a season',
          record: 'Jennifer Brannigan - 2014 - 45',
        },
        {
          name: 'Most podiums in a season (Advanced and above)',
          record: 'Bruce Davidson - 2015 - 12',
        },
        // {
        //   name: 'Highest Season OBP (5 runs or more)',
        //   record: 'Pia Munker - 2011 - 96.7%',
        // },
        // {
        //   name: 'Highest Season OBP (5 runs or more, 4* & 5*)',
        //   record: 'Michael Jung - 2014 - 95.2%',
        // },
        {
          name: 'Highest Season OBP (5 runs or more)',
          record: 'Erin Contino - 2015 -95%',
        },
        {
          name: 'Highest Season OBP (5 runs or more, 4* & 5*)',
          record: 'Marilyn Little - 2014 - 89%',
        },
        {
          name: 'Most sub-28 finishings in a season (All-Levels)',
          record: 'Ruan Wood - 2015 - 32',
        },
        {
          name: 'Most sub-28 finishings in a season (4* & 5*)',
          record: 'Tamara Smith - 2015 - 3',
        },
      ],
    },
  ];
  return (
    <>
      <HStack align="center">
        <TitleContainer>
          <Text size="x6">Records (since 2014)</Text>
        </TitleContainer>
      </HStack>
      <Spacer height="x4" />
      {records.map((item, idx) => {
        return <RecordBox title={item.title} items={item.items} />;
      })}
    </>
  );
};

export default Records;
