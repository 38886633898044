import React, { useState } from "react";
import styled from "styled-components";
import { navigate } from "@reach/router";

import { theme } from "../config";

import VStack from "./VStack";
import HStack from "./HStack";
import Text from "./Text";

const Container = styled.div`
  display: none;
  @media (${theme.breakpoints.mediumAndUp}) {
    display: flex;
    min-width: 200px;
    background-color: ${theme.colors.white100};
    border-right: 0.5px solid ${theme.colors.grey30};
    &:hover {
      color: ${theme.colors.white100};
    }
  }
`;

const MobileContainer = styled.div`
  display: flex;
  position: absolute;
  height: 100vh;
  width: 60%;
  background-color: ${theme.colors.white100};
  z-index: 1000;
  border-right: 0.5px solid ${theme.colors.grey30};
  transition: transform 300ms ease-in-out;
  @media (${theme.breakpoints.mediumAndUp}) {
    transition: transform 300ms ease-in-out;
    display: none;
  }
`;
const SidebarItemContainer = styled.div`
  width: 100%;
  padding: ${theme.spacing.x2} 0;
  color: ${({ active }) =>
    active ? theme.colors.white100 : theme.colors.primary100};
  background: ${({ active }) =>
    active ? theme.colors.primary100 : theme.colors.white100};
  &:hover {
    background: ${theme.colors.primary100};
    color: ${theme.colors.white100};
  }
  cursor: pointer;
`;
const ItemText = styled.h1`
  font-size: ${theme.spacing.x5};
  font-weight: 600;
  padding-left: ${theme.spacing.x4};
`;

const SidebarItem = ({ text, active, setActiveRoute }) => {
  return (
    <SidebarItemContainer active={active} onClick={setActiveRoute}>
      <HStack align="center" justify="space-between">
        <ItemText>{text}</ItemText>
      </HStack>
    </SidebarItemContainer>
  );
};

const Sidebar = ({
  showSidebar,
  activeRoute,
  setActiveRoute,
  setShowSidebar,
}) => {
  const menuItems = [
    { title: "Dashboard", route: "/dashboard" },
    // {title: 'Elo Ratings', route: '/elo'},
    { title: "All-time Stats", route: "/alltime" },
    { title: "Records", route: "/records" },
  ];
  if (showSidebar) {
    return (
      <MobileContainer>
        <VStack>
          {menuItems.map((item, idx) => {
            return (
              <SidebarItem
                key={idx}
                text={item.title}
                closeSidebar={() => setShowSidebar(false)}
                setActiveRoute={async () => {
                  await localStorage.setItem("activeRoute", idx);
                  await setActiveRoute(idx);
                  await setShowSidebar(false);
                  navigate(item.route);
                }}
                active={activeRoute === idx}
              />
            );
          })}
        </VStack>
      </MobileContainer>
    );
  } else {
    return (
      <Container>
        <VStack>
          {menuItems.map((item, idx) => {
            return (
              <SidebarItem
                key={idx}
                text={item.title}
                setActiveRoute={async () => {
                  await localStorage.setItem("activeRoute", idx);
                  await setActiveRoute(idx);
                  navigate(item.route);
                }}
                active={activeRoute === idx}
              />
            );
          })}
        </VStack>
      </Container>
    );
  }
};

export default Sidebar;
