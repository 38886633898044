import React, { useEffect } from "react";
import { Router, navigate } from "@reach/router";

import {
  SingleMetric,
  Records,
  AllTime,
  Dashboard,
  EloRankings,
  EloSearch,
} from "./screens";

const Navigation = () => {
  return (
    <>
      <Router>
        <Dashboard path="/dashboard" default />
        <AllTime path="/alltime" />
        <Records path="/records" />
        <SingleMetric path="/analysis" />
        {/* <EloRankings path="/elo" />
        <EloSearch path="/elo-search" /> */}
      </Router>
    </>
  );
};

export default Navigation;
