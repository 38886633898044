import { camelizeKeys, decamelizeKeys } from "humps";

const buildCacheKey = (key) => `network-cache-${key}`;
const fetchLocally = async (cacheKey) => {
  const cachedValueStr = await localStorage.getItem(cacheKey);
  return JSON.parse(cachedValueStr);
};
const cacheLocally = async (cacheKey, data) =>
  await localStorage.setItem(cacheKey, JSON.stringify(data));

const buildFetchOptions = async (method, options = { authToken: false }) => {
  const fetchOptions = {
    method,
    headers: {
      Accept: "application/json",
    },
  };

  const methodsWithPayload = ["POST", "PUT"];
  if (methodsWithPayload.includes(method)) {
    fetchOptions.headers["Content-Type"] = "application/json";
    fetchOptions.body = JSON.stringify(decamelizeKeys(options.payload));
  }

  //   if (options.authToken) {
  //     const token = await auth.getUserToken()
  //     fetchOptions.headers['Authorization'] = `Bearer ${token}`
  //   }

  return fetchOptions;
};

const fetcher = async (
  method,
  url,
  onSuccess,
  onError,
  options = { authToken: false }
) => {
  try {
    const fetchOptions = await buildFetchOptions(method, options);
    const response = await fetch(url, fetchOptions);
    const responseJSON = await response.json();
    const processedJSON =
      response.status !== 204 ? camelizeKeys(responseJSON) : null;

    response.status !== 204 && onSuccess(processedJSON);

    if (method === "GET" && options.cacheKey) {
      await cacheLocally(options.cacheKey, processedJSON);
    }
  } catch (e) {
    onError(e);
  }
};

const get = async (url, onSuccess, onError, options) => {
  const cacheKey = buildCacheKey(url);
  const cachedData = await fetchLocally(cacheKey);
  if (cachedData) onSuccess(cachedData);

  await fetcher("GET", url, onSuccess, onError, { ...options, cacheKey });
};

const post = async (url, payload, onSuccess, onError, options) =>
  await fetcher("POST", url, onSuccess, onError, { ...options, payload });

const put = async (url, payload, onSuccess, onError, options) =>
  await fetcher("PUT", url, onSuccess, onError, { ...options, payload });

const destroy = async (url, onError, options) =>
  await fetcher("DELETE", url, () => console.log("noop"), onError, options);

export default { get, post, put, destroy };
