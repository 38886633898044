import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {format, subYears, differenceInCalendarDays} from 'date-fns';
import {
  Dropdown,
  ExpandedStatList,
  Filter,
  HStack,
  Spacer,
  Text,
  VStack,
  EloTable,
} from '../components';
import {theme} from '../config';
import {useResource} from '../hooks';
import {navigate} from '@reach/router';

const Banner = styled.div`
  width: 100%;
  height: 20%;
  position: fixed;
  background-image: url(https://images.squarespace-cdn.com/content/v1/58810210197aea38928d567d/1488393188386-4FK72GNYDQP0ZLUAR941/ke17ZwdGBToddI8pDm48kBY-qbJWPsJyl0YWCpcFDbUUqsxRUqqbr1mOJYKfIPR7LoDQ9mXPOjoJoqy81S2I8N_N4V1vUb5AoIIIbLZhVYy7Mythp_T-mtop-vrsUOmeInPi9iDjx9w8K4ZfjXt2dtpiMAtR4kZNhqC87Kai_7eqPC5xDA4RuAfsTyVeslMUZDqXZYzu2fuaodM4POSZ4w/Discover+Eventing+Banner.jpg?format=2500w);
  background-color: ${theme.colors.grey100};
  opacity: 0.8;
`;

const BannerOverlay = styled.div`
  width: 100%;
  height: 20%;
  position: fixed;
  background-color: ${theme.colors.grey100};
  opacity: 0.4;
`;

const Divider = styled.div`
  height: 2px;
  width: 100%;
  background: ${theme.colors.grey40};
`;

const ListsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: wrap;
  @media (${theme.breakpoints.mediumAndUp}) {
    flex-direction: row;
  }
`;

const AllTimeExplainer = styled.div`
  display: flex;
  justify-content: right;
  @media (${theme.breakpoints.mediumAndUp}) {
    justify-content: left;
  }
`;

const TitleContainer = styled.div`
  flex-wrap: wrap;
  display: flex;
  @media (${theme.breakpoints.mediumAndUp}) {
    flex-wrap: no-wrap;
  }
`;

const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  @media (${theme.breakpoints.mediumAndUp}) {
    flex-direction: row;
    width: 300px;
    align-items: top;
  }
`;

const OutterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (${theme.breakpoints.mediumAndUp}) {
    flex-direction: row;
    width: 100%;
  }
`;

const FilterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${theme.spacing.x4};
  @media (${theme.breakpoints.mediumAndUp}) {
    margin-top: 0;
  }
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 30px;
  margin-top: 10px;
  @media (${theme.breakpoints.mediumAndUp}) {
    margin-top: 0;
  }
`;
const ExplainerTextContainer = styled.div`
  width: 100%;
  @media (${theme.breakpoints.mediumAndUp}) {
    width: 80%;
  }
`;
const Input = styled.input`
  box-sizing: border-box;
  border: 2px solid ${theme.colors.grey20};
  font-size: ${({fontSize}) => fontSize || theme.fontScale.x4};
  font-weight: ${theme.fontWeight.semibold};
  color: ${theme.colors.primary100};
  padding: ${({padding}) => (padding ? padding : theme.spacing.x2)};
  outline: none;
  width: 100%;
  @media (${theme.breakpoints.mediumAndUp}) {
    margin-top: 0;
    width: 25%;
  }
`;

const EloRankings = props => {
  const [activeFilter, setActiveFilter] = useState(0);
  const [groupBy, setGroupBy] = useState('current');
  const [nation, setSelectedNation] = useState('All Nations');
  const [selectedLevels, setSelectedLevel] = useState([]);
  const [horseName, setHorseName] = useState('');
  const [riderName, setRiderName] = useState('');
  const [timeframe, setTimeframe] = useState('current');

  let levelParams = '';
  if (selectedLevels.length > 0) {
    selectedLevels.forEach(level => {
      levelParams = levelParams + `er_levels[]=${level.level}&`;
    });
  }
  let yearParams = '';

  let horseFilter = '';
  let athleteFilter = '';
  let timeFrame = '';
  if (horseName) {
    horseFilter = `&horses_filter[name_contains]=${horseName}`;
  }
  if (riderName) {
    athleteFilter = `&athletes_filter[display_name_contains]=${riderName}`;
  }

  const twoYearsAgo = subYears(Date.now(), 2);
  if (groupBy == 'current') {
    let timeFrame = format(twoYearsAgo, 'yyyy-MM-dd');
  }

  if (groupBy == 'allTime') {
    let timeFrame = '2008-01-01';
  }

  let scope = '';
  if (groupBy == 'allTime') {
    scope = '&scope=all_time';
  }

  const {getAll: getAllElos, all: elos} = useResource(
    `elo?return_top=25&date_from=${
      groupBy == 'current' ? format(twoYearsAgo, 'yyyy-MM-dd') : '2008-01-01'
    }${horseFilter}${athleteFilter}${scope}&${
      nation === 'All Nations' ? '' : `nf=${nation}`
    }&`,
  );
  const {getAll: getAllFederations, all: federations} = useResource(
    'federations',
  );

  useEffect(() => {
    getAllElos();
  }, [selectedLevels, groupBy, nation, getAllElos, horseName, riderName]);

  useEffect(() => {
    getAllFederations();
  }, [getAllFederations]);

  const removeClass = index => {
    const newArr = [...selectedLevels];
    newArr.splice(index, 1);
    setSelectedLevel(newArr);
  };

  const camelize = s => {
    return s.replace(/([-_][a-z])/gi, $1 => {
      return $1
        .toUpperCase()
        .replace('-', '')
        .replace('_', '');
    });
  };

  return (
    <>
      <Text size="x6" weight="semibold">
        Horse Elo Ratings
      </Text>
      <Spacer height="x2" />
      <ExplainerTextContainer>
        <Text size="x4">
          All horses have an Elo rating going into each international event,
          which represents their form at that particular moment. The average is
          around 300, with the best horses going into the 900s. When they beat
          another horse, their rating goes up and when they lose, their rating
          goes down. It ranks horses based on their competitiveness. The Elo
          Ratings include horses which have competed in the last two seasons.
          <a
            href="https://www.equiratings.com/howtheeventingeloworks"
            target="blank"
            style={{
              'text-decoration': 'none',
              'font-size': '20px',
              'font-weight': '600',
              color: 'rgba(26, 56, 82, 1)',
              'white-space': 'nowrap',
              pointer: 'cursor',
            }}>
            {' '}
            How it works.
          </a>
        </Text>
      </ExplainerTextContainer>
      <Spacer height="x6" />
      <OutterWrapper>
        <Input
          placeholder="Search Rider"
          onChange={e => setRiderName(e.target.value)}
          onFocus={e => (e.target.value = '')}
        />
        <Spacer width="x2" />
        <Input
          placeholder="Search Horse"
          onChange={e => setHorseName(e.target.value)}
          onFocus={e => (e.target.value = '')}
        />
        <Spacer width="x2" />
        <DropdownContainer>
          {federations && (
            <Dropdown
              onChange={item => setSelectedNation(item.attributes.code)}
              readOnly={false}
              items={[
                {
                  attributes: {
                    name: 'All Federations',
                    code: 'All Nations',
                  },
                },
                ...federations.data,
              ]}
              itemToString={item => item.attributes.code}
              initialItem={{
                attributes: {name: 'All Federations', code: 'All Nations'},
              }}
              filterKey="code"
              displaySuggestion={item => (
                <Text size="x3">{item.attributes.code}</Text>
              )}
            />
          )}
        </DropdownContainer>
        <FilterContainer>
          <div
            onClick={() => {
              setActiveFilter(0);
              setGroupBy('current');
            }}>
            <Filter active={activeFilter === 0} text="Current" />
          </div>
          <div
            onClick={() => {
              setActiveFilter(1);
              setGroupBy('allTime');
            }}>
            <Filter active={activeFilter === 1} text="All Time" />
          </div>
        </FilterContainer>
      </OutterWrapper>
      <Spacer hieght="x3" />
      {groupBy == 'allTime' && (
        <AllTimeExplainer>
          <Text size="x3" color="grey30" align="right">
            *All time Elos are from 2008 on
          </Text>
        </AllTimeExplainer>
      )}
      <Spacer height="x6" />
      {elos && elos.data && elos.data.length > 0 ? (
        <EloTable
          list={elos.data}
          isAllTime={groupBy == 'allTime' ? true : false}
        />
      ) : (
        <Text size="x5" color="grey30" weight="semibold" align="center">
          There are no records matching your search.
        </Text>
      )}
      <Spacer height="x8" />
    </>
  );
};

export default EloRankings;
