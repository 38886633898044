import React from "react";
import styled from "styled-components";
import emoji from "node-emoji";
import { navigate } from "@reach/router";

import { theme } from "../config";
import Text from "./Text";
import VStack from "./VStack";
import HStack from "./HStack";
import Spacer from "./Spacer";

const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  margin-top: ${theme.spacing.x4};
  flex-direction: column;
  @media (${theme.breakpoints.mediumAndUp}) {
    margin-top: 0;
  }
`;

const Divider = styled.div`
  height: 2px;
  width: 100%;
  background: ${theme.colors.primary100};
`;

const ListItemContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: ${theme.spacing.x2} ${theme.spacing.x2};
  background: ${({ idx }) =>
    idx % 2 === 0 ? theme.colors.white100 : theme.colors.grey20};
`;

const Column = styled.div`
  width: ${({ width }) => width};
  padding: 0 ${theme.spacing.x1};
`;

const ListItem = ({ item, idx }) => {
  return (
    <ListItemContainer idx={idx}>
      <HStack align="center" justify="space-between">
        <HStack align="center">
          <Column width={"10%"}>
            <Text
              color="grey100"
              size="x2"
              weight="bold"
            >{`${item.rank}.`}</Text>
          </Column>
          <Column width={"10%"}>
            <img
              src={`https://data.fei.org/Content/Images/Flags/${item.groupValue}.png`}
            />
          </Column>
          <Column width={"60%"}>
            <Text color="grey100" size="x2" weight="semibold">
              {item.groupLabel}
            </Text>
          </Column>
        </HStack>
        <Column width={"20%"}>
          <Text color="grey100" size="x3" weight="bold">
            {item.metricValue}
          </Text>
        </Column>
      </HStack>
    </ListItemContainer>
  );
};

const ExpandedStatList = ({ title, list }) => {
  return (
    <Container>
      <VStack>
        <Text size="x5" weight="semibold">
          {title}
        </Text>
        <Spacer height="x1" />
        <Divider />
        {list.map((item, idx) => {
          return <ListItem key={idx} item={item} idx={idx} />;
        })}
      </VStack>
    </Container>
  );
};

export default ExpandedStatList;
