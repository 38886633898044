import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { StatList, Filter, HStack, Spacer, Text, VStack } from "../components";
import { theme } from "../config";
import { useResource } from "../hooks";
import { navigate } from "@reach/router";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
  justify-content: center;
`;

const NavContainer = styled.div`
  width: 90%;
  height: 100%;
  padding: ${theme.spacing.x4};
  @media (${theme.breakpoints.mediumAndUp}) {
    width: 50%;
    height: 75%;
  }
`;

const Button = styled.div`
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  border: 2px solid ${theme.colors.grey15};
  height: 200px;
  width: 100%;
  cursor: pointer;
  :hover {
    transform: scale(1.05);
  }
  margin-bottom: ${theme.spacing.x4};
  @media (${theme.breakpoints.mediumAndUp}) {
    height: 450px;
    width: 49%;
  }
`;

const Pill = styled.div`
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  border: 2px solid ${theme.colors.grey15};
  padding: ${theme.spacing.x4} ${theme.spacing.x6};
  width: 100%;
  justify-content: center;
  cursor: pointer;
  :hover {
    transform: scale(1.05);
  }
  margin-bottom: ${theme.spacing.x4};
  @media (${theme.breakpoints.mediumAndUp}) {
    width: 49.5%;
  }
`;
const Image = styled.img`
  height: 85px;
`;

const MobileHStack = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${({ justify }) => justify};
  align-items: ${({ align }) => align};
  flex-grow: ${({ grow }) => grow};
  @media (${theme.breakpoints.mediumAndUp}) {
    flex-direction: row;
  }
`;

const PreliminaryScreen = ({ setActiveRoute }) => {
  return (
    <Container>
      <NavContainer>
        <VStack align="center">
          <Spacer height="x4" />
          <Image src={require("../assets/eiLogo.png")} />
          <Spacer height="x8" />
          <Text
            color="secondary80"
            size="x6"
            weight="semibold"
            align="center"
            mobileSize="x5"
          >
            Welcome to the Eventing Ireland
          </Text>
          <Text size="x7" weight="bold" align="center" mobileSize="x6">
            Stats Centre
          </Text>
        </VStack>
        <Spacer height="x8" />
        <MobileHStack align="center" justify="space-between">
          <Button
            onClick={() => {
              setActiveRoute(3);
              navigate("/equilume-league");
            }}
          >
            <VStack align="center" justify="center">
              <Image
                src={require("../assets/equilume.png")}
                style={{ alignSelf: "center", margin: "0 auto" }}
              />
              <Spacer height="x8" />
              <Text size="x5" weight="semibold" align="center" mobileSize="x5">
                Equilume Power Average League
              </Text>
            </VStack>
          </Button>
          <Button
            onClick={() => {
              setActiveRoute(4);
              navigate("/redmills-league");
            }}
          >
            <VStack align="center" justify="center">
              <Image
                src={require("../assets/redmills.png")}
                style={{ alignSelf: "center", margin: "0 auto" }}
              />
              <Spacer height="x8" />
              <Text size="x5" weight="semibold" align="center" mobileSize="x5">
                Red Mills OBP League
              </Text>
            </VStack>
          </Button>
        </MobileHStack>
        <Spacer height="x4" />
        <MobileHStack align="center" justify="space-between">
          <Pill
            onClick={() => {
              setActiveRoute(0);
              navigate("/dashboard");
            }}
          >
            <Text size="x5" weight="semibold" align="center" mobileSize="x5">
              Dashboard
            </Text>
          </Pill>
          {/* <Pill
            onClick={() => {
              setActiveRoute(2);
              navigate("/records");
            }}
          >
            <Text size="x5" weight="semibold" align="center" mobileSize="x5">
              Records
            </Text>
          </Pill> */}
          <Pill
            onClick={() => {
              setActiveRoute(1);
              navigate("/alltime");
            }}
          >
            <Text size="x5" weight="semibold" align="center" mobileSize="x5">
              All Time
            </Text>
          </Pill>
          {/* <Pill onClick={() => navigate("elo")}>
            <Text size="x5" weight="semibold" align="center" mobileSize="x5">
              Elo Ratings
            </Text>
          </Pill> */}
        </MobileHStack>
      </NavContainer>
    </Container>
  );
};

export default PreliminaryScreen;
